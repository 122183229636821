
import {defineComponent, computed} from 'vue';
import Spinner from '@/components/Spinner.vue';
import articles from "@/services/articles";
import Breadcrumbs from "@/components/ui/Breadcrumbs.vue";
import BaseIcon from "@/components/ui/BaseIcon.vue";
import CommodityGroupsParamModal from "@/components/modals/CommodityGroupsParamModal.vue";
import DropdownSearchCommodityGroups from "@/components/dropdown/DropdownSearchCommodityGroups.vue";
import params from "@/services/params";
import AddParamModal from "@/components/modals/AddParamModal.vue";

export default defineComponent({
  name: 'CommodityGroupsParam',
  components: {
    Spinner,
    Breadcrumbs,
    BaseIcon,
    CommodityGroupsParamModal,
    DropdownSearchCommodityGroups,
    AddParamModal,
  },
  props: {
    customTitle: {
      type: String,
      default: () => null
    }
  },
  data: () => ({
    pageable: null as any,
    isSearchAllParams: false as any,
    currentParam: null as any,
    showAll: false as boolean,
    isLoading: true as boolean,
    commodityGroups: [] as any,
    arrSequentialValues: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Ч', 'Ц', 'Ш', 'Щ', 'Ъ', 'Ы', 'Ь', 'Э', 'Ю', 'Я'],
  }),
  computed: {
    getNameCommodityGroup(): any {
      const groupName = this.$store.getters.getGroupName

      if (this.$route.query.name) {
        return `Параметры по товарной группы - ${this.$route.query.name}`
      }

      if (groupName) {
        return `Параметры по товарной группы - ${groupName}`
      }

      if (JSON.parse(localStorage.getItem('group')!).id === +this.$route.params.id) {
        return `Параметры по товарной группы - ${JSON.parse(localStorage.getItem('group')!).value}`
      }

      return `Параметры по товарной группы - ID ${this.$route.params.id}`
    }
  },
  methods: {
    closeAddParamModal() {
      let element = document.getElementById(`modal-add-param-group`)!
      element.style.display = 'none'
    },
    openModalAddParam() {
      setTimeout(() => {
        let element = document.getElementById(`modal-add-param-group`)!
        element.style.display = 'flex'
      }, 0)
    },
    previous() {
      if (!!this.pageable.pageNumber) {
        this.setParam(this.pageable.pageNumber - 1)
      }
    },
    next () {
      if ((this.pageable.pageNumber + 1) !== this.pageable.totalPages) {
        this.setParam(this.pageable.pageNumber + 1)
      }
    },
    openCommodityGroupsParamModal(param: any) {
      this.currentParam = param
      setTimeout(() => {
        let element = document.getElementById(`modal-commodity-groups-param-edit`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeCommodityGroupsParamModal() {
      let element = document.getElementById(`modal-commodity-groups-param-edit`)!
      element.style.display = 'none'
    },
    async setParam(page = 0) {
      this.isLoading = true
      if (this.customTitle) {
        this.getAllParams(page)
      } else {
        await params.getAllParamsByProductGroup(+this.$route.params.id).then(async (responseByProductGroup: any) => {
          if (!responseByProductGroup.length) {
            // this.getAllParams(page)
            this.isSearchAllParams = true
            this.commodityGroups = []
          } else {
            this.isSearchAllParams = true
            this.commodityGroups = responseByProductGroup
          }
        })
      }
      this.isLoading = false
    },
    async getAllParams(page: any) {
      await params.getAllParameters(page).then((responseAllParams: any) => {
        this.isSearchAllParams = false
        this.pageable = {
          ...responseAllParams.pageable,
          totalPages: responseAllParams.totalPages
        }
        this.commodityGroups = responseAllParams.content
      })
    },
    setFoundCommodityGroupParams(content: any) {
      this.isSearchAllParams = true
      this.commodityGroups = content
    },
    clearFoundCommodityGroupParams() {
      this.setParam(this.pageable && this.pageable.pageNumber)
    },
    setOption(option: any) {
      this.openCommodityGroupsParamModal(option)
    }
  },
  async mounted() {
    await this.setParam()
  },
})
