
import { defineComponent } from 'vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";

export default defineComponent({
  name: 'CommodityGroupsParamItem',
  data: () => ({
    editMode: false,
    fieldValue: null,
    vehicleTypes: [] as any,
  }),
  components: {
    BaseIcon,
  },
  props: {
    field: {
      default: () => null
    },
    param: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    getInfoField() {
      return (field: any) => {
        switch (field) {
          case 'id':
            return 'id'
          case 'name':
            return `Название`
          case 'desc':
            return `Описание`
          case 'description':
            return `Описание`
          case 'descShort':
            return `Краткое описание`
          case 'units':
            return `Единица измерения`
          default:
            return null
        }
      }
    }
  },
  methods: {
    changeFieldValue(value: any) {
      this.$emit('changeValue', this.field, value)
    },
    cancelChangesField() {
      this.changeFieldValue(null)
      this.editMode = false
    },
  },
})
