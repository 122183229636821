
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupFieldItem from "@/components/commodity-groups/CommodityGroupFieldItem.vue";
import params from "@/services/params";

export default defineComponent({
  name: 'AddCommodityGroupModal',
  emits: ['addNewParam', 'closeAddParamModal', 'addedNewParam', 'closeModal'],
  components: { ModalWrapper, CommodityGroupFieldItem },
  data: () => ({
    error: null as any,
    dataNewParam: {
      name: null as any,
      units: null as any,
    } as any
  }),
  props: {
    title: {
      type: String,
      default: () => 'Добавление товарной группы'
    },
    productGroupId: Number
  },
  methods: {
    closeModal() {
      this.dataNewParam.name = ''
      this.$emit('closeModal')
    },
    setDataCommodityGroup(fieldName: string, value: string) {
      this.dataNewParam[fieldName] = value
    },
    async addCommodityGroup() {
      const payload = {
        lang: "RUS",
        units: this.dataNewParam.units,
        name: this.dataNewParam.name,
        mandatory: true,
        productGroupId: this.$route.params.id || this.productGroupId
      }

      await params.addNewParam(payload).then((newParam: any) => {
        this.dataNewParam.name = ''
        this.$emit('addNewParam', newParam)
        this.$emit('closeAddParamModal')
      })
    },
  },
})
